import React from 'react';

const TextareaField = ({ label, name, register, errors }) => {
    return (
        <div>
            <label>{label}</label>
            <textarea {...register(name)}></textarea>
            <p className="p-error">{errors[name]?.message}</p>
        </div>
    );
};

export default TextareaField;
