import React from 'react';

const FileField = ({ label, name, register, errors }) => {
    return (
        <div>
            <label>{label}</label>
            <input type="file" {...register(name)} />
            <p className="p-error">{errors[name]?.message}</p>
        </div>
    );
};

export default FileField;
