import './App.css';
import Form from "./components/Form";
import imagen from "./assets/img/factoring1.jpg";
function App() {
    return (
        <div className="App">
            <div className="content-container">
                <div className="form-container">
                    <Form/>
                </div>
                <div className="text-container">
                    <img src={imagen} width="500"/>
                    <h2>¡Simplifica tu gestión financiera con nuestro servicio de factoring!</h2>
                    <p>
                        En Mando Integral Servicio Financieros, entendemos la importancia de un flujo de caja constante.
                        Envía tus facturas de manera rápida y segura, y obtén el capital que necesitas
                        para hacer crecer tu negocio sin complicaciones. ¡Nuestro equipo se encargará del resto!
                    </p>
                    <p className="highlight-text">¡No pierdas tiempo! Conviértete en un cliente satisfecho hoy
                        mismo.</p>
                </div>

            </div>
        </div>
    );
}

export default App;


