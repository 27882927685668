import React from 'react';

const InputField = ({ label, name, register, errors, ...rest }) => {
    return (
        <div>
            <label>{label}</label>
            <input {...register(name)} {...rest} />
            <p className="p-error">{errors[name]?.message}</p>
        </div>
    );
};

export default InputField;
