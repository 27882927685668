import React from 'react';

const SelectField = ({ label, name, register, errors, options }) => {
    return (
        <div>
            <label>{label}</label>
            <select {...register(name)}>
                <option value="">Selecciona el tipo de consulta</option>
                {options.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                ))}
            </select>
            <p className="p-error">{errors[name]?.message}</p>
        </div>
    );
};

export default SelectField;
