import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { format, validate } from 'rut.js';
import Swal from 'sweetalert2';

import InputField from './InputField';
import SelectField from './SelectField';
import TextareaField from './TextareaField';
import FileField from './FileField';
import ReCaptcha from './ReCaptcha';
import './Form.css';
import {base_url} from "../config/config";
import axios from "axios";



const schema = yup.object().shape({
    nombre: yup.string().required('El nombre es obligatorio'),
    rut_empresa: yup.string().required('El RUT es obligatorio').test('validRut', 'RUT inválido', value => validate(value)),
    correo: yup.string().required('El correo es obligatorio').email('Correo inválido'),
    telefono: yup.string()
        .required('El teléfono es obligatorio')
        .matches(/^[98765]\d{7}$/, 'Teléfono inválido'), // Nueva expresión regular
    mensaje: yup.string().required('El mensaje es obligatorio'),
    tipo_consulta: yup.string().required('El tipo de consulta es obligatorio'),
    factura: yup.mixed().required('La factura es obligatoria').test('fileType', 'Solo se permiten archivos PDF', (value) => {
        return value && value[0] && value[0].type === 'application/pdf';
    })
});




const Form = () => {
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append('nombre', data.nombre);
        formData.append('rut_empresa', data.rut_empresa);
        formData.append('correo', data.correo);
        formData.append('telefono', data.telefono);
        formData.append('mensaje', data.mensaje);
        formData.append('tipo_consulta', data.tipo_consulta);
        formData.append('factura', data.factura[0]);
        //formData.append('g-recaptcha-response', data['g-recaptcha-response']);

        try {
            const response = await axios.post(`${base_url}/submit`, formData);
            console.log(response);
            Swal.fire({
                icon: 'success',
                title: 'Formulario enviado con éxito',
                text: 'Su formulario ha sido enviado correctamente.',
            }).then(() => {
                limpiar();
            });
        } catch (error) {
            console.error(error);
            Swal.fire({
                icon: 'error',
                title: 'Error al enviar el formulario',
                text: 'Hubo un problema al enviar su formulario. Por favor, inténtelo de nuevo más tarde.',
            });
        }
    };

    const limpiar = () => {
        setValue('nombre', '');
        setValue('rut_empresa', '');
        setValue('correo', '');
        setValue('telefono', '');
        setValue('mensaje', '');
        setValue('tipo_consulta', '');
        setValue('factura', '');
    }

    const handleRutChange = (e, onChange) => {
        const formattedRut = format(e.target.value);
        onChange(formattedRut);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
            <InputField label="Nombre" name="nombre" register={register} errors={errors} />
            <Controller
                name="rut_empresa"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <InputField
                        label="RUT Empresa"
                        name="rut_empresa"
                        value={value}
                        onChange={(e) => handleRutChange(e, onChange)}
                        register={register}
                        errors={errors}
                    />
                )}
            />
            <InputField label="Correo" name="correo" type="email" register={register} errors={errors} />
            <InputField label="Teléfono" name="telefono" type="tel" register={register} errors={errors} />
            <TextareaField label="Mensaje" name="mensaje" register={register} errors={errors} />
            <SelectField
                label="Días de pago de factura"
                name="tipo_consulta"
                register={register}
                errors={errors}
                options={[
                    { value: 'Evaluar factura a 30 días', label: 'Evaluar factura a 30 días' },
                    { value: 'Evaluar factura a 60 dias', label: 'Evaluar factura a 60 dias ' },
                    { value: 'Evaluar factura a 90 días o mas', label: 'Evaluar factura a 90 días o mas' },
                ]}
            />
            <FileField label="Factura (PDF)" name="factura" register={register} errors={errors} />
           {/* <ReCaptcha register={register} setValue={setValue} />*/}
            <button type="submit">Enviar</button>
        </form>
    );
};

export default Form;

